<template> </template>

<script>
export default {
    beforeCreate() {
        this.$router.push('/iam/projects/create')
        localStorage.setItem(`welcome-${this.$auth.user().id}`, true)
    },
}
</script>

<style lang="scss"></style>
